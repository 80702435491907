<template>
  <LayoutBase>
    <LoadingProgress :loading="loading"></LoadingProgress>
    <MobileSpiner :loading="loading"></MobileSpiner>
    <tools />
  </LayoutBase>
</template>

<script>
import MobileSpiner from "../components/UI/MobileSpiner";
import LoadingProgress from "../components/UI/LoadingProgress";
import LayoutBase from "../components/layouts/LayoutBase";

import Tools from "../modules/toolses/Tools.vue";
export default {
  components: {
    MobileSpiner,
    LoadingProgress,
    LayoutBase,
    Tools,
  },
};
</script>

<style>
</style>