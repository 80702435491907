import api from "@/api/index";
import store from "@/store";
import {
    setEmoji
} from "@/handlers/emoji";

export function postMailingStatus(that) {
    api
        .post("/user/change_mailing_status")
        .then((r) => {
            console.info(r.statusText + " " + r.status + " " + setEmoji("access"));
            console.log(r.data.mailing);
            that.isMailing = r.data.mailing
            that.$toast.add({
                severity: "success",
                summary: `Ежедневная рассылка остатков ${r.data.mailing ? 'включена' : 'отключена'}`,
                life: 5000,
            });
            //   (that.isSearchLoad = false), (that.loadingCard = false);
            //   if (r.data.result.length != 1) {
            //     that.cards = null;
            //   } else {
            //     r.data.result.forEach((i) => {
            //       let checkStatus;
            //       switch (i.status.pk) {
            //         case 1:
            //           checkStatus = true;
            //           break;
            //         default:
            //           checkStatus = false;
            //           break;
            //       }
            //       i.checkStatus = checkStatus;
            //       checkStatus
            //         ? (i.checkMess = "Заблокировать")
            //         : (i.checkMess = "Разблокировать");
            //       i.disabled = false;
            //     });
            //     that.card = r.data.result[0];
            //   }
        })
        .catch((err) => {
            that.isMailing = store.state.curUser.mailing,
            console.log(err);
            let statusCode = err.request.status;
            let statusText = err.request.statusText;
            console.error(setEmoji("error") + statusCode + " " + statusText);
            switch (statusCode) {
                case 0:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("error")} Нет связи с сервером`,
                        detail: "Попробуйте позднее!",
                    });
                    break;
                case 403:
                    that.$toast.add({
                        severity: "warn",
                        summary: `${setEmoji("lock")} Время сессии истекло`,
                        detail: "Требуется авторизация",
                        life: 5000,
                    });
                    that.$router.push("/login");
                    break;
                case 404:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji("error")} Ошибка ${statusCode}`,
                        detail: "Запрос не найден",
                        life: 5000,
                    });
                    break;
                default:
                    that.$toast.add({
                        severity: "error",
                        summary: `${setEmoji(
              "error"
            )} Ошибка получения списка карт  ${statusCode}`,
                        detail: "Попробуйте позднее!",
                        life: 5000,
                    });
                    break;
            }
        })
        .finally(() => {
            setTimeout(() => {
                that.loading = false;
            }, 3000)
        });
}