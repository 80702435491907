<template> 
    <div class="mt-2 surface-card p-4 shadow-2 border-round">
      <TabView>
        <TabPanel header="Оповещения и рассылки">
          <Mailing />
        </TabPanel>
        <TabPanel :disabled="true" header="Другие"> </TabPanel>
      </TabView>
    </div>
 
</template>

<script>
import Mailing from "./components/Mailing.vue";
export default {
  components: { Mailing },
  data() {
    return {
      isMailing: this.$store.state.curUser.mailing,
    };
  },
  computed: {},
};
</script>

<style>
</style>