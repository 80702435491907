<template>
  <div class="flex align-items-center">
    <p class="mr-2">Ежедневная рассылка остатков:</p>
    <div v-tooltip="setTooltip">
      <InputSwitch
        :disabled="loading"
        @input="postMailStatus"
        v-model="isMailing"
      />
    </div>
  </div>
</template>
  
  <script>
import { postMailingStatus } from "../api/mailing";
export default {
  name: "Mailing",
  data() {
    return {
      isMailing: this.$store.state.curUser.mailing,
      loading: false,
      tooltip: this.isMailing ? "Отключить" : "Включить",
    };
  },
  methods: {
    postMailStatus() {
      this.loading = true;
      postMailingStatus(this);
    },
  },
  computed: {
    setTooltip() {
      return this.isMailing ? "Отключить" : "Включить";
    },
  },
};
</script>
  
  <style>
</style>